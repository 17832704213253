<template>
<div>
  <v-overlay
    :value="isLoading"
    z-index="9999"
  >
    <v-progress-circular
      indeterminate
      size="64"
    ></v-progress-circular>
  </v-overlay>
  <v-container fluid class="crm-container d-flex blue-grey lighten-4 flex-grow-1">
    <v-row class="text-no-wrap flex-nowrap mx-0 mx-md-4">
      <v-col class="crm-column">
        <BaseCRMColumn
          :currentUser="currentUser"
          :column-width="columnWidth"
          :applications="APPLICATIONS_NEW"
          column-id="new"
          title="New Clients"
          data-cy="new-applications-column"
        >
          <template #action>
            <v-btn
              x-small
              outlined
              text
              @click="createNewApplication"
              data-cy="btn-add-new-application"
            >
              ADD NEW
            </v-btn>
          </template>
        </BaseCRMColumn>
      </v-col>
      <v-col
        v-for="column in columns"
        :key="column.columnId"
        class="crm-column"
      >
        <component
          :currentUser="currentUser"
          :is="'BaseCRMColumn'"
          :column-id="column.id"
          :column-width="column.width"
          :applications="column.applications"
          :title="column.title"
          :data-cy="column.dataCy"
        />
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import 'firebase/firestore';

import {
  getCurrentUserId,
} from '@/services/firebase';

export default {
  name: 'DashboardAdviser',

  components: {
    BaseCRMColumn: () => import('@/components/BaseCRMColumn.vue'),
  },

  data() {
    return {
      columnWidth: 190,
      isLoading: false,
    };
  },

  async created() {
    await this.$store.dispatch('getApplications', getCurrentUserId());
  },

  computed: {
    ...mapGetters([
      'APPLICATIONS',
      'APPLICATIONS_NEW',
      'APPLICATIONS_COMPLETED',
      'APPLICATIONS_SUBMITTED',
      'APPLICATIONS_APPROVAL',
      'APPLICATIONS_CONFIRMED',
      'APPLICATIONS_SETTLED',
      'APPLICATIONS_FINALIZED',
    ]),

    currentUser() {
      return getCurrentUserId();
    },

    columns() {
      const columns = [
        {
          id: 'completed',
          width: this.columnWidth,
          applications: this.APPLICATIONS_COMPLETED,
          title: 'Fact Find Completed',
          dataCy: 'completed-applications-column',
        },

        {
          id: 'submitted',
          width: this.columnWidth,
          applications: this.APPLICATIONS_SUBMITTED,
          title: 'Application Submitted',
          dataCy: 'submitted-applications-column',
        },

        {
          id: 'approval',
          width: this.columnWidth,
          applications: this.APPLICATIONS_APPROVAL,
          title: 'Conditional Approval',
          dataCy: 'approval-applications-column',
        },

        {
          id: 'confirmed',
          width: this.columnWidth,
          applications: this.APPLICATIONS_CONFIRMED,
          title: 'Loan Structure Confirmed',
          dataCy: 'confirmed-applications-column',
        },

        {
          id: 'settled',
          width: this.columnWidth,
          applications: this.APPLICATIONS_SETTLED,
          title: 'Loan Settled',
          dataCy: 'settled-applications-column',
        },
      ];

      return columns;
    },
  },

  methods: {
    async createNewApplication() {
      this.isLoading = true;

      const application = await this.$store.dispatch('createApplication');

      const isApplicationNotCreated = Boolean(application === null);
      if (isApplicationNotCreated) {
        return;
      }

      this.$store.commit('changeComplainStatus', false);

      await this.$router.push({
        name: 'ApplicationAdviser',
        params: {
          id: application?.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-container {
  height: 100%;
  overflow-x: auto;
}
.crm-column {
  @media (max-width: 1199px) {
    width: calc(100% / 6);
    min-width: 200px;
    box-sizing: content-box;
  }
}
</style>
