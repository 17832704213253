var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-overlay', {
    attrs: {
      "value": _vm.isLoading,
      "z-index": "9999"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1), _c('v-container', {
    staticClass: "crm-container d-flex blue-grey lighten-4 flex-grow-1",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "text-no-wrap flex-nowrap mx-0 mx-md-4"
  }, [_c('v-col', {
    staticClass: "crm-column"
  }, [_c('BaseCRMColumn', {
    attrs: {
      "currentUser": _vm.currentUser,
      "column-width": _vm.columnWidth,
      "applications": _vm.APPLICATIONS_NEW,
      "column-id": "new",
      "title": "New Clients",
      "data-cy": "new-applications-column"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "x-small": "",
            "outlined": "",
            "text": "",
            "data-cy": "btn-add-new-application"
          },
          on: {
            "click": _vm.createNewApplication
          }
        }, [_vm._v(" ADD NEW ")])];
      },
      proxy: true
    }])
  })], 1), _vm._l(_vm.columns, function (column) {
    return _c('v-col', {
      key: column.columnId,
      staticClass: "crm-column"
    }, [_c('BaseCRMColumn', {
      tag: "component",
      attrs: {
        "currentUser": _vm.currentUser,
        "column-id": column.id,
        "column-width": column.width,
        "applications": column.applications,
        "title": column.title,
        "data-cy": column.dataCy
      }
    })], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }